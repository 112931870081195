
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { defaultMsgList, removeDefaultMsg, saveDefaultMsg } from "@/api/request/system";

//组件
@Component({
  name: "DefaultMsg",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    lang: "",
  };

  //语言列表
  private langList: any[] = [
    { lang: "", name: "全部语言" },
    { lang: "zh", name: "中文" },
    { lang: "en", name: "英文" },
    { lang: "id", name: "印尼语" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await defaultMsgList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: "",

      //请求数据
      id: 0, //ID
      sort: 0, //排序
      lang: "", //归属语言
      content: "", //消息内容
    };

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: String(row.sort),

      //请求数据
      id: row.id, //ID
      sort: row.sort, //排序
      lang: row.lang, //归属语言，不可编辑
      content: row.content, //消息内容
    };

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await removeDefaultMsg({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑默认消息", create: "新增默认消息" };

  //添加表单
  private addForm: any = {
    //临时数据
    sort_str: "",

    //请求数据
    id: 0, //ID
    sort: 0, //排序
    lang: "", //归属语言，不可编辑
    content: "", //消息内容
  };

  //语言列表
  private langSelect: any[] = [
    { lang: "", name: "请选择语言" },
    { lang: "zh", name: "中文" },
    { lang: "en", name: "英文" },
    { lang: "id", name: "印尼语" },
  ];

  //创建消息
  private async createData() {
    //判断返回
    if (this.addForm.content == "") return Message({ message: "请输入消息内容！", type: "error", duration: 5 * 1000 });
    if (this.addForm.lang == "") return Message({ message: "请选择归属语言！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //添加
    await saveDefaultMsg(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新消息
  private async updateData() {
    //判断返回
    if (this.addForm.content == "") return Message({ message: "请输入消息内容！", type: "error", duration: 5 * 1000 });
    if (this.addForm.lang == "") return Message({ message: "请选择归属语言！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //添加
    await saveDefaultMsg(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}
