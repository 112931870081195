//本地导入
import request from "../request";

//获取排行榜列表
export const getRankList = () =>
  request({
    method: "post",
    url: "/adm/system/rank/list",
  });

//获取排行榜列表
export const setRank = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/rank/set",
  });

//默认消息列表
export const defaultMsgList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/list",
  });

//删除默认消息
export const removeDefaultMsg = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/remove",
  });

//保存默认消息
export const saveDefaultMsg = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/save",
  });

//融云推送模板-类型
export const ryPushTplType = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/type",
  });

//融云推送模板-保存
export const ryPushTplSave = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/save",
  });

//融云推送模板-移除
export const ryPushTplRemove = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/remove",
  });

//融云推送模板-列表
export const ryPushTplList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/list",
  });
